import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { AwsS3Service } from '../aws-s3/aws-s3.service';
import { PainelViewModel } from '../models/painel-view-model';
import { UsuariosService } from '../usuarios/usuarios.service';

@Component({
  selector: 'app-cliente-sem-diretorio',
  templateUrl: './cliente-sem-diretorio.component.html',
  styleUrls: ['./cliente-sem-diretorio.component.css']
})
export class ClienteSemDiretorioComponent implements OnInit {

  pesquisa: PainelViewModel = new PainelViewModel;
  paginaAtual : Number = 1 ;
  listaConsultores: Array<any> = [];
  listaClientes: Array<any> = [];
  listaClientesFiltro: Array<any> = [];
  modalError: boolean = false;
  modalErrorMessage: string = "";

  constructor(
    private route: ActivatedRoute,
    private awsS3Service: AwsS3Service,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private usuariosService: UsuariosService
  ) { }

  ngOnInit() {

    this.primengConfig.ripple = true;      
    this.obterClienteSemBackup();    
    this.pesquisa.configurarParametros();    
    this.obterConsultores();

  }

  obterConsultores() {
    this.usuariosService.obterConsultores().subscribe(
       retorno => {
          this.listaConsultores = retorno;
       },
       error => {
          this.messageService.add({
             severity: "error",
             summary: "Consultor",
             detail: `Erro ao obter consultores!`
          });
       }
    );
  }

  obterClienteSemBackup(){
   
    this.awsS3Service.obterBackupManager().subscribe(
      retorno => {
      this.listaClientes = JSON.parse(retorno.ClientesSemBabckup);
      this.listaClientesFiltro = JSON.parse(retorno.ClientesSemBabckup);
      },
      error =>{
      this.modalErrorMessage = error;    
      this.modalError = true;
      }
    );

  }

  filtrarCliente(){

      let query = {};
   
      if (this.pesquisa.codigo) {
          query['cliId'] = this.pesquisa.codigo;
      }
   
      if (this.pesquisa.consultor) {
          query['clivendPref'] = this.pesquisa.consultor;
      }
   
      this.listaClientesFiltro = this.listaClientes.filter( (item) => {
             
            for (let key in query) {
               if (!item[key] || !query[key].toString().includes(item[key])) { // Filtro dinâmico  
                   return false;
               }
            }
   
             if(this.pesquisa.razao_fantasia){ //verificar por Razão / Fantasia
               return (item.cliFantasia.toLowerCase().indexOf(this.pesquisa.razao_fantasia) != -1 || 
               item.cliNome.toLowerCase().indexOf(this.pesquisa.razao_fantasia) != -1);
             }
    
            return true;
   
         }
   
      )

   }

}

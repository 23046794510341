
<form>

    <div >
        <div class="row d-flex justify-content-between">
    
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <nav aria-label="breadcrumb" class="mb-3">
                  
                  <h4>Clientes sem Diretório AWS</h4>
                </nav>    
            </div>

            <!-- <h3 class="mr-3"><span class="badge badge-dark">Clientes sem Backup</span></h3> -->
    
        </div>
    
        <div class="row">
    
        </div>
    
        <div class="card mt-2">
            <div class="card-body">
      
              <div class="row">
      
                  <div class="col-sm-12 col-md-3 col-lg-2 col-xl-2">
                    <div class="form-group">
                        <label for="id" class="col-form-label-sm">Código</label>
                        <input type="number" id="id" name="id" class="form-control form-control-sm noscroll"
                            placeholder="Código" [(ngModel)]="pesquisa.codigo" >
                    </div>
                  </div>
      
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <div class="form-group">
                        <label for="fantasia" class="col-form-label-sm">Razão/Fantasia</label>
                        <input type="text" id="fantasia" name="fantasia" class="form-control form-control-sm noscroll"
                            placeholder="Razão/Fantasia" [(ngModel)]="pesquisa.razao_fantasia" >
                    </div>
                  </div>

                  <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2">
                      <label class="col-form-label-sm custom-select-label">Consultor</label>
                      <select id="consultor" name="consultor" class="form-control form-control-sm" [(ngModel)]="pesquisa.consultor">
                          <option [ngValue]="0" >-Selecione-</option>
                          <option *ngFor="let item of listaConsultores" [ngValue]="item.cliId" >{{ item.cliNome }}</option>
                      </select>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-2 col-xl-2 mt-4">
                      <button class="btn btn-sm btn-dark" (click)="filtrarCliente()"><i class="material-icons">search</i>Pesquisar</button>
                  </div>
                    
                  <div class="col-sm-6 col-md-6 col-lg-1 col-xl-1">
                      <label class="col-form-label-sm custom-select-label">Itens/Pág.</label>
                      <select id="lst_modal" name="lst_modal" class="form-control form-control-sm" [(ngModel)]="pesquisa.qdtItemsPagincao">
                          <option [ngValue]="-1">Todos</option>
                          <option [ngValue]="20">20</option>
                          <option [ngValue]="50">50</option>
                          <option [ngValue]="100">100</option>
                          <option [ngValue]="300">300</option>
                          <option [ngValue]="500">500</option>
                          <option [ngValue]="1000">1000</option>
                      </select>
                  </div>

              </div>
      
              <div class="card-body pt-1 pb-1 bg-white card-body-table">
                  <div class="table-responsive-sm">
                      <table class="table table-sm table-borderless table-striped table-hover ">
                          <thead class="thead">
                              <th class="text-nowrap" >Código</th>
                              <th class="text-nowrap" >Razão</th>
                              <th class="text-nowrap" >Fantasia</th>
                              <th class="text-nowrap cursor">Consultor</th>
                          </thead>
                          <tbody>
                              <tr class="cursor" *ngFor="let item of listaClientesFiltro | paginate: { itemsPerPage: pesquisa.qdtItemsPagincao, currentPage: paginaAtual }">
                                  <td>{{item.cliId}}</td>
                                  <td>{{item.cliNome}}</td>
                                  <td>{{item.cliFantasia}}</td>
                                  <td class="text-nowrap">{{item.clivendPref ? item.cliNomeVendedor : 'SEM CONSULTOR'}}</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>


                </div>

                <div class="row d-flex justify-content-between">
                
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3">
                      <span><b>Total: </b>{{listaClientesFiltro.length}}</span>
                    </div>

                    <div class="text-right mt-3 mr-2">
                      <pagination-controls (pageChange)="paginaAtual = $event" previousLabel="Anterior" nextLabel="Próximo"></pagination-controls>
                    </div>

                </div>
              
            </div>
        </div>
    
    </div>

    <p-dialog header="Retorno API" [(visible)]="modalError" [modal]="true" [style]="{width: '90vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">

        <div class="row">
            <p>{{modalErrorMessage | json}}</p>
        </div>

        <p-footer>
            <p-button icon="pi pi-check" label="Ok" class="p-button-text" (click)="modalError = false"></p-button>
        </p-footer>

    </p-dialog>

</form>


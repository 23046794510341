import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioViewModel } from '../models/usuario-view-model';
import { NavMenuService } from './nav-menu.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;

  mostrarMenu: boolean = true;

  usuario = JSON.parse(sessionStorage.getItem('usuario'));

  constructor(
    private router: Router,
    public navMenu: NavMenuService,
  ){
    this.navMenu.show();
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  logout(){
    sessionStorage.removeItem('usuario');
    window.location.reload();

  }

}

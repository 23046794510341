<form [formGroup]="cadastroForm">

  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
  
      <nav aria-label="breadcrumb" class="mb-3">
        <h5>Usuário - Cadastro</h5>
      </nav>
    </div>
  
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="text-right btns-group">
          <a class="btn btn-sm btn-secondary" href="javascript:window.history.back();"><i class="material-icons">arrow_back</i>Voltar</a>
            <a class="btn btn-sm btn-success" (click)="onSubmit()"><i class="material-icons" >done_all</i>Salvar</a>
        </div>
    </div>
  
  </div>
  
    <div class="card mt-3">
        <div class="card-body">
  
          
            <div class="row">
  
                <div class="col-sm-6 col-md-3 col-lg-2 col-xl-1">
                    <div class="form-group">
                        <label for="id" class="col-form-label-sm" >Código</label>
                        <input type="number" id="id" name="id" class="form-control form-control-sm noscroll"
                            placeholder="Código" formControlName="id" readonly>
                    </div>
                </div>
  
                
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                  <div class="form-group">
                      <label for="dt_cadastro" class="col-form-label-sm">Data Cadastro</label>
                      <input type="date" id="dt_cadastro" name="dt_cadastro" 
                          class="form-control form-control-sm" autocomplete="off" readonly formControlName="dataCadastro" value="{{ objUsuario.dataCadastro | date:'yyyy-MM-dd'}}"/>
                  </div>
                </div>
  
                
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-1">
                    <div class="form-check mt-4">
                      <input type="checkbox" class="form-check-input" id="ativo" formControlName="ativo">
                      <label class="form-check-label" for="ativo">Ativo</label>
                    </div>
                </div>
  
            </div>
  
            <div class="row">
  
              
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <div class="form-group">
                      <label for="email" class="col-form-label-sm">Email</label>
                      <input type="email" id="email" name="email"
                          class="form-control form-control-sm" placeholder="Email do Usuário"
                          autocomplete="off" formControlName="email"/>
                  </div>
                </div>
  
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label for="fantasia" class="col-form-label-sm">Nome</label>
                        <input type="text" id="fantasia" name="fantasia"
                            class="form-control form-control-sm" placeholder="Nome"
                            autocomplete="off" formControlName="nome"/>
                    </div>
                </div>
              
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
                    <div class="form-group">
                      <label for="senha" class="col-form-label-sm">Senha</label>
                      <input type="password" class="form-control form-control-sm" id="senha" placeholder="Senha" formControlName="senha">
                    </div>  
                </div>
  
  
                <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
                    <label class="col-form-label-sm custom-select-label">Tipo de Usuário</label>
                    <select id="lst_modal" name="lst_modal" class="form-control form-control-sm" formControlName="tipoUsuario">
                        <option [ngValue]="1" >Admin</option>
                        <option [ngValue]="2" >Suporte</option>
                    </select>
                </div>
  
  
            </div>
  
        </div>
    </div>

    <p-dialog header="Retorno API" [(visible)]="modalError" [modal]="true" [style]="{width: '90vw'}" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">
        <div class="row">
            <p>{{modalErrorMessage | json}}</p>
        </div>

        <p-footer>
            <p-button icon="pi pi-check" label="Ok" class="p-button-text" (click)="modalError = false"></p-button>
        </p-footer>

    </p-dialog>

</form>

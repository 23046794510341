export class PainelViewModel {

    codigo: string;
    razao_fantasia: string;
    razao: string;
    consultor: number = 0;

    qdtItemsPagincao: number = 20;

    dtAtual: Date = new Date();
    dt0: Date = new Date();
    dt2: Date = new Date();
    dt5: Date = new Date();
    dt10: Date = new Date();

    dt_inicial: String = '';
    dt_final: String = '';
 
    listarDiretorios: Array<any> = [];

    semBackup: Array<any> = [];
    doisDiasBackup: Array<any> = [];
    cincoDiasBackup: Array<any> = [];
    dezDiasBackup: Array<any> = [];
 
 
    clienteSelecionadoCodigo: String;
    clienteSelecionadoStringDownload: String;
    clienteSelecionadoUltimaModificacao: String;
    clienteSelecionadoNome: String;
    clienteSelecionadoFantasia: String;
    clienteSelecionadoFone1: String;
    clienteSelecionadoFone2: String;
    clienteSelecionadoEmail: String;

    configurarParametros(){
        this.dt2.setDate(this.dtAtual.getDate() - 2);
        this.dt5.setDate(this.dtAtual.getDate() - 5);
        this.dt10.setDate(this.dtAtual.getDate() - 10);
    }

    limparData(){
        this.dt_inicial = undefined;
        this.dt_final = undefined;
    }
 
}
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UsuarioViewModel } from '../models/usuario-view-model';

@Injectable({
  providedIn: 'root'
})


export class UsuariosService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  adicionar(objUsuario: UsuarioViewModel): Observable<any>{
    return this.http.post(`${this.baseUrl}api/Usuarios`, objUsuario);
  }

  atualizar(objUsuario: UsuarioViewModel): Observable<any>{
    return this.http.put(`${this.baseUrl}api/Usuarios/${objUsuario.id}`, objUsuario);
  }

  obterTodos(): Observable<any>{
    return this.http.get(`${this.baseUrl}api/Usuarios`);
  }

  obterPorId(id): Observable<any>{
    return this.http.get(`${this.baseUrl}api/Usuarios/${id}`);
  }
  obterPorEmail(objUsuario: UsuarioViewModel): Observable<any>{
    return this.http.post(`${this.baseUrl}api/Usuarios/Email`, objUsuario);
  }

  obterConsultores(): Observable<any>{
    return this.http.get(`${this.baseUrl}api/ClienteMaxdata/Consultor`);
  }

}

<main class="form-signin mt-5">
  <form>
    <img class="mb-4" src="../../assets/logo_max_backup.svg" alt="../../assets/logo_max_backup.svg" width="300" height="130">

    <h1 class="h3 mb-3 fw-normal text-center"></h1>

    <div class="form-floating mb-3">
      <label for="email">Email</label>
      <input type="email" class="form-control" id="email" name="email" placeholder="nome@exemplo.com" [(ngModel)]="email">
    </div>
 
    <div class="form-floating">
      <label for="senha">Senha</label>
      <input type="password" class="form-control" id="senha" name="senha" placeholder="Password" [(ngModel)]="senha" (keydown.enter)="login()">
    </div>

    <div class="checkbox mb-3">
      <label>
        <!-- <input type="checkbox" value="remember-me"> Remember me -->
      </label>
    </div>

    <!-- <button class="w-100 btn btn-lg btn-primary" type="button" (click)="login()">Entrar</button> -->



    <button class="w-100 btn btn-lg {{loginStyle}}" id="login" type="button" (click)="login()"
        [disabled]="processandoLogin">
        <div [ngSwitch]="processandoLogin">
            <div *ngSwitchCase="true">
                <span class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>&nbsp;
                <span>{{loginMensagem}}</span>
            </div>
            <div *ngSwitchDefault>
                <span>Entrar</span>
            </div>
        </div>
    </button>

    <p class="mt-5 text-muted text-center">&copy; 2022 - Todos os direitos reservados</p>

    <p class="mb-3 text-muted text-center ">MaxBackup</p>

    <div class="d-flex justify-content-center">
        <span class="material-icons">bug_report</span>
        <p>{{version}}</p>
    </div>

    
    <p-dialog header="Retorno API" [(visible)]="modalError" [modal]="true" [style]="{width: '90vw'}" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">
        <div class="row">
            <p>{{modalErrorMessage}}</p>
        </div>

        <p-footer>
            <p-button icon="pi pi-check" label="Ok" class="p-button-text"></p-button>
        </p-footer>
    </p-dialog>
    

  </form>

</main>
<form [formGroup]="cadastroForm">

  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
  
      <nav aria-label="breadcrumb" class="mb-3">
        <h4><span class="badge {{ tipoUsuario.value == 1 ? 'badge-warning' : 'badge-secondary' }} " >{{ tipoUsuario.value == 1 ? 'ADMIN' : 'SUPORTE' }}</span></h4>
      </nav>
    </div>
  
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="text-right btns-group">
          <a class="btn btn-sm btn-secondary" href="javascript:window.history.back();"><i class="material-icons">arrow_back</i>Voltar</a>
            <a class="btn btn-sm btn-success" (click)="atualizar()"><i class="material-icons">done_all</i>Salvar</a>
        </div>
    </div>
  
  </div>
  
    <div class="card mt-3">
        <div class="card-body">
  
          
            <div class="row">
  
                <div class="col-sm-6 col-md-3 col-lg-2 col-xl-1">
                    <div class="form-group">
                        <label for="id" class="col-form-label-sm">Código</label>
                        <input type="number" id="id" name="id" class="form-control form-control-sm noscroll"
                            placeholder="Código" formControlName="id" readonly>
                    </div>
                </div>
  
                
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                  <div class="form-group">
                      <label for="nome" class="col-form-label-sm">Data Cadastro</label>
                      <input type="text" id="dt_nascimento" name="dt_nascimento"
                          class="form-control form-control-sm" autocomplete="off" value="{{ dataCadastro.value | date: 'dd/MM/yyyy' }}" readonly />
                  </div>
                </div>
  
            </div>
  
            <div class="row">
  
              
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <div class="form-group">
                      <label for="email" class="col-form-label-sm">Email</label>
                      <input type="email" id="email" name="email"
                          class="form-control form-control-sm" placeholder="Email do Usuário"
                          autocomplete="off" readonly formControlName="email"/>
                  </div>
                </div>
  
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label for="fantasia" class="col-form-label-sm">Nome</label>
                        <input type="text" id="fantasia" name="fantasia"
                            class="form-control form-control-sm" placeholder="Nome"
                            autocomplete="off" formControlName="nome"/>
                    </div>
                </div>
              
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
                    <div class="form-group">
                      <label for="senha" class="col-form-label-sm">Alterar Senha</label>
                      <input type="password" class="form-control form-control-sm" id="senha" placeholder="Senha" formControlName="senha">
                    </div>  
                </div>
  
  
            </div>
  
        </div>
    </div>

</form>

<div class="row">

    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">

        <nav aria-label="breadcrumb" class="mb-3">
          <h5>Clientes</h5>
        </nav>

    </div>

    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="text-right btns-group">
          <a class="btn btn-sm btn-outline-secondary" href="javascript:window.history.back();"><i class="material-icons">arrow_back</i>Voltar</a>
            <!-- <a class="btn btn-sm btn-outline-success"><i class="material-icons">done_all</i>Salvar</a> -->
        </div>
    </div>

</div>



<div class="card mt-2">
    <div class="card-body">

      <div class="card-body pt-1 pb-1 bg-white card-body-table">
        <div class="table-responsive-sm">
            <table class="table table-sm table-borderless table-striped table-hover ">
                <thead class="thead">
                    <th class="text-nowrap" >Código</th>
                    <th class="text-nowrap" >Nome</th>
                    <th class="text-nowrap" >Última Modificação</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr class="cursor">
                        <td>1</td>
                        <td>Maxdata Sistemas</td>
                        <td>05/01/2021 18:09</td>
                        <td class="text-center">
                            <div class="badge bg-warning text-dark" tooltip="Pessoa Física" placement="left">ATUAL</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

      
    </div>
</div> 
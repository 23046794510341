import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router } from "@angular/router";


@Injectable()

export class AuthGuard implements CanLoad, CanActivate{

    usuario = JSON.parse(sessionStorage.getItem('usuario'));

    constructor(
        protected router: Router
        ) { }

    canLoad() : boolean {
        return false;        
    }

    canActivate(activate: ActivatedRouteSnapshot) : boolean {        

        if (activate.data[`tipoUsuario`]) {

            if (this.usuario.TipoUsuario == activate.data[`tipoUsuario`]) return true;

            this.router.navigate([`login`]);

            return false;
        } 

        if (!this.usuario) {

            this.router.navigate([`login`]);

            return false;
        }

        return true;
    }    

}
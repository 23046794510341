import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { AwsS3Component } from './aws-s3/aws-s3.component';
import { ClienteComponent } from './cliente/cliente.component';
import { PainelComponent } from './painel/painel.component';
import { PerfilUsuarioComponent } from './perfil-usuario/perfil-usuario.component';
import { UsuarioCadastroComponent } from './usuario-cadastro/usuario-cadastro.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard } from './services/app.guard';
import { NgxPaginationModule } from 'ngx-pagination';
import { PainelWebComponent } from './painel-web/painel-web.component';
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ToastModule } from "primeng/toast";

import { ConfirmationService, MessageService } from "primeng/api";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { DialogModule } from 'primeng/dialog';
import { ClienteSemDiretorioComponent } from './cliente-sem-diretorio/cliente-sem-diretorio.component';


@NgModule({
  declarations: [									
    AppComponent,
    NavMenuComponent,
    UsuariosComponent,
    UsuarioCadastroComponent,
    AwsS3Component,
    ClienteComponent,
    PainelComponent,
    PainelWebComponent,
    PerfilUsuarioComponent,
    LoginComponent,
    NotFoundComponent,
    ClienteSemDiretorioComponent
   ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      { path: '', component: LoginComponent, pathMatch: 'full' },
      { path: 'aws-s3', component: AwsS3Component, canActivate: [AuthGuard], data: {tipoUsuario: 1}},
      { path: 'painel', component: PainelComponent, canActivate: [AuthGuard]},
      { path: 'painel-web', component: PainelWebComponent, canActivate: [AuthGuard], data: {tipoUsuario: 1}},
      { path: 'cliente-sem-diretorio', component: ClienteSemDiretorioComponent, canActivate: [AuthGuard]},
      { path: 'cliente', component: ClienteComponent, canActivate: [AuthGuard], data: {tipoUsuario: 1} },
      { path: 'cliente-consulta', component: ClienteComponent, canActivate: [AuthGuard], data: {tipoUsuario: 1} },
      { path: 'usuario', component: UsuariosComponent, canActivate: [AuthGuard], data: {tipoUsuario: 1} },
      { path: 'usuario-novo', component: UsuarioCadastroComponent, canActivate: [AuthGuard], data: {tipoUsuario: 1} },
      { path: 'usuario-editar/:id', component: UsuarioCadastroComponent, canActivate: [AuthGuard], data: {tipoUsuario: 1} },
      { path: 'perfil-usuario', component: PerfilUsuarioComponent, canActivate: [AuthGuard] },
      { path: 'login', component: LoginComponent },
      { path: '**', component: NotFoundComponent },
    ]),
    ConfirmPopupModule,
    ToastModule,
    ButtonModule,
    RippleModule,
    DialogModule,
    ButtonModule
  ],
  providers: [AuthGuard, ConfirmationService, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }


<form>

    <div *ngIf="!modalCliente; else detalheCliente" >
        <div class="row d-flex justify-content-between">
    
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <nav aria-label="breadcrumb" class="mb-3">
                  
                  <h4>Consulta: {{ dataConsulta | date: 'dd/MM/yyyy HH:mm' }}</h4>
                </nav>    
            </div>

            <h3 class="mr-3"><span class="badge badge-danger">Manager</span></h3>
    
        </div>
    
        <div class="row">
    
            <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 p-1 cursor" (click)="pesquisarDiretorios2DiasSemBackup(); cardAtivo = 1;">
                <div class="card text-white card-color-silver " [ngClass]="cardAtivo == 1 ? 'active' : ''">
                    <div class="d-flex justify-content-center card-title mt-2"><h5>2 Dias</h5></div>
                    <div class="card-body text-white card-color-silver" [ngClass]="cardAtivo == 1 ? 'active' : ''">            
                        <div class="d-flex justify-content-center"><h1 class="display-6">{{pesquisa.doisDiasBackup.length}}</h1></div>              
                    </div>
                </div>
            </div>
      
            <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 p-1 cursor" (click)="pesquisarDiretorios5DiasSemBackup(); cardAtivo = 2;">
                <div class="card text-white card-color-silver" [ngClass]="cardAtivo == 2 ? 'active' : ''">
                    <div class="d-flex justify-content-center card-title mt-2"><h5>5 Dias</h5></div>
                    <div class="card-body text-white card-color-silver" [ngClass]="cardAtivo == 2 ? 'active' : ''">            
                        <div class="d-flex justify-content-center"><h1 class="display-6">{{pesquisa.cincoDiasBackup.length}}</h1></div>            
                    </div>
                </div>
            </div>
      
            <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 p-1 cursor" (click)="pesquisarDiretorios10DiasSemBackup(); cardAtivo = 3;">
                <div class="card text-white card-color-silver" [ngClass]="cardAtivo == 3 ? 'active' : ''">
                    <div class="d-flex justify-content-center card-title mt-2"><h5>6 Dias ou Mais</h5></div>
                    <div class="card-body text-white card-color-silver" [ngClass]="cardAtivo == 3 ? 'active' : ''">            
                        <div class="d-flex justify-content-center"><h1 class="display-6">{{pesquisa.dezDiasBackup.length}}</h1></div>            
                    </div>
                </div>
            </div>
      
            <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 p-1 cursor" [routerLink]="['/cliente-sem-diretorio']">
                <div class="card text-white card-color-silver" >
                    <div class="d-flex justify-content-center card-title mt-2"><h5>Sem Diretório AWS</h5></div>
                    <div class="card-body text-white card-color-silver">            
                        <div class="d-flex justify-content-center"><h1 class="display-6">{{listaClientes.length}}</h1></div>            
                    </div>
                </div>
            </div>
    
    
        </div>
    
        <div class="card mt-2">
          <div class="card-body">
    
            <div class="row">
    
                <div class="col-sm-12 col-md-3 col-lg-2 col-xl-2">
                  <div class="form-group">
                      <label for="id" class="col-form-label-sm">Código</label>
                      <input type="number" id="id" name="id" class="form-control form-control-sm noscroll"
                          placeholder="Código" [(ngModel)]="pesquisa.codigo" (keydown.enter)="pesquisarDiretorio()">
                  </div>
                </div>
    
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <div class="form-group">
                      <label for="fantasia" class="col-form-label-sm">Razão/Fantasia</label>
                      <input type="text" id="fantasia" name="fantasia" class="form-control form-control-sm noscroll"
                          placeholder="Razão/Fantasia" [(ngModel)]="pesquisa.razao_fantasia" (keydown.enter)="pesquisarDiretorio()">
                  </div>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2">
                    <label class="col-form-label-sm custom-select-label">Consultor</label>
                    <select id="consultor" name="consultor" class="form-control form-control-sm" [(ngModel)]="pesquisa.consultor">
                        <option [ngValue]="0" >-Selecione-</option>
                        <option *ngFor="let item of listaConsultores" [ngValue]="item.cliId" >{{ item.cliNome }}</option>
                    </select>
                </div>
        
                <!-- <div class="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                  <div class="form-group">
                      <label for="dt_emissao_inicial" class="col-form-label-sm cursor text-dark" >Período</label>
                      <div class="input-group">
                          <input type="date" id="dt_emissao_inicial" name="dt_emissao_inicial"
                              class="form-control form-control-sm" [(ngModel)]="pesquisa.dt_inicial">
                          <span class="ml-1 mr-1 mt-1">a</span>
                          <input type="date" id="dt_emissao_final" name="dt_emissao_final" 
                                  class="form-control form-control-sm"  [(ngModel)]="pesquisa.dt_final">
                          <div class="input-group-append">
                              <button class="btn btn-sm btn-danger" type="button" id="remover_dt" [disabled]="!pesquisa.dt_inicial && !pesquisa.dt_final"
                                      (click)="pesquisa.limparData()">
                                  <i class="material-icons mr-0">remove</i>
                              </button>
                          </div>
                      </div>
                    </div>                    
                </div> -->

  
                <div class="col-sm-12 col-md-6 col-lg-2 col-xl-2 mt-4">
                    <button class="btn btn-sm btn-dark" (click)="pesquisarDiretorio()"><i class="material-icons">search</i>Pesquisar</button>
                </div>
                  
                <div class="col-sm-6 col-md-6 col-lg-1 col-xl-1">
                    <label class="col-form-label-sm custom-select-label">Itens/Pág.</label>
                    <select id="lst_modal" name="lst_modal" class="form-control form-control-sm" [(ngModel)]="pesquisa.qdtItemsPagincao">
                        <option [ngValue]="-1" >Todos</option>
                        <option [ngValue]="20" >20</option>
                        <option [ngValue]="50" >50</option>
                        <option [ngValue]="100" >100</option>
                        <option [ngValue]="300" >300</option>
                        <option [ngValue]="500" >500</option>
                        <option [ngValue]="1000" >1000</option>
                    </select>
                </div>

            </div>    
    
            <div class="card-body pt-1 pb-1 bg-white card-body-table">
              <div class="table-responsive-sm">
                  <table class="table table-sm table-borderless table-striped table-hover ">
                      <thead class="thead">
                          <th class="text-nowrap" >Código</th>
                          <th class="text-nowrap" >Razão</th>
                          <th class="text-nowrap" >Fantasia</th>
                          <th class="text-nowrap cursor" (click)="ordenarPorTamanhoAnterior()" >Tamanho Anterior</th>
                          <th class="text-nowrap cursor" (click)="ordenarPorTamanho()" >Tamanho Atual</th>
                          <th ></th>
                          <th class="text-nowrap cursor" (click)="ordenarPorData()" >Último Backup</th>
                          <th class="text-nowrap cursor" (click)="ordenarPorData()" >Consultor</th>
                      </thead>
                      <tbody>
                          <tr class="cursor" *ngFor="let item of pesquisa.listarDiretorios | paginate: { itemsPerPage: pesquisa.qdtItemsPagincao, currentPage: paginaAtual }" (click)="obterObjetoCliente(item)">
                              <td>{{item.Codigo}}</td>
                              <td>{{item.Cliente != null ? item.Cliente.cliNome : 'DADOS INCONSISTENTES'}}</td>
                              <td>{{item.Cliente != null ? item.Cliente.cliFantasia : 'DADOS INCONSISTENTES'}}</td>
                              <td>{{item.PreviusSize < 1024 ? (item.PreviusSize / (1024)).toFixed(2) + ' KB' : 
                                    item.PreviusSize > (1024 * 1024 * 1024) ? (item.PreviusSize / (1024 * 1024 * 1024)).toFixed(2) + ' GB' : 
                                    (item.PreviusSize / (1024 * 1024)).toFixed(2) + ' MB' }}
                              </td>
                              <td>{{item.Size < 1024 ? (item.Size / (1024)).toFixed(2) + ' KB' : 
                                  item.Size > (1024 * 1024 * 1024) ? (item.Size / (1024 * 1024 * 1024)).toFixed(2) + ' GB' : 
                                  (item.Size / (1024 * 1024)).toFixed(2) + ' MB' }}
                              </td>
                              <td class="text-danger" >
                                <span *ngIf="item.Size < (item.PreviusSize - 20971520) || !item.Cliente" class="material-icons-outlined">report_problem</span>
                              </td>
                              <td class="text-nowrap">{{item.LastModified | date: 'dd/MM/yyyy HH:mm'}}</td>
                              <td class="text-nowrap">{{item?.Cliente?.cliNomeVendedor ? item.Cliente.cliNomeVendedor : 'SEM CONSULTOR'}}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>


            </div>

            <div class="row d-flex justify-content-between">
            
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3">
                    <span><b>Total: </b>{{pesquisa.listarDiretorios.length}}</span>
                  </div>

                  <div class="text-right mt-3 mr-2">
                    <pagination-controls (pageChange)="paginaAtual = $event" previousLabel="Anterior" nextLabel="Próximo"></pagination-controls>
                  </div>
             

            </div>

            
            
          </div>
        </div> 
    
    </div>
    
    
    <ng-template #detalheCliente>
    
        <div class="row">
    
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <nav aria-label="breadcrumb" class="mb-3">
                  <h5>Detalhes</h5>
                </nav>
            </div>
    
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="text-right btns-group">
                  <a class="btn btn-sm btn-secondary" (click)="limparDadosObjetoCliente()"><i class="material-icons">arrow_back</i>Voltar</a>
                </div>
            </div>    
    
        </div>
    
    
        <div class="card text-center">
            <div class="card-header">
                <h5>{{pesquisa.clienteSelecionadoCodigo}}</h5>
            </div>
            <div class="card-body">
                <h5 class="card-title">{{pesquisa.clienteSelecionadoNome}} - {{pesquisa.clienteSelecionadoFantasia}}</h5>
                <p class="card-text">{{pesquisa.clienteSelecionadoFone1}} | {{pesquisa.clienteSelecionadoFone2}}</p>
                <p class="card-text">{{pesquisa.clienteSelecionadoEmail}}</p>
            </div>
            <div class="card-footer text-muted" *ngFor="let item of listaBackupsCliente">

                <div class="row d-flex justify-content-around">

                  <span><b>{{item.LastModified | date: 'dd/MM/yyyy HH:mm'}}</b></span>

                  <h5><span class="badge badge-dark">
                    {{
                      item.Key.split('/')[3].split('_')[1] == 1 ? 'Domingo':
                      item.Key.split('/')[3].split('_')[1] == 2 ? 'Segunda':
                      item.Key.split('/')[3].split('_')[1] == 3 ? 'Terça':
                      item.Key.split('/')[3].split('_')[1] == 4 ? 'Quarta':
                      item.Key.split('/')[3].split('_')[1] == 5 ? 'Quinta':
                      item.Key.split('/')[3].split('_')[1] == 6 ? 'Sexta':
                      item.Key.split('/')[3].split('_')[1] == 7 ? 'Sábado': 'Sem Semana'
                    }}
                  </span></h5>

                  <b> {{item.Key.split('/')[3]}}</b>

                  <b>{{item.Size < 1024 ? (item.Size / (1024)).toFixed(2) + ' KB' : 
                                  item.Size > (1024 * 1024 * 1024) ? (item.Size / (1024 * 1024 * 1024)).toFixed(2) + ' GB' : 
                                  (item.Size / (1024 * 1024)).toFixed(2) + ' MB' }}
                  </b>

                  <div class="btn-group" role="group">

                      <button type="button" class="btn btn-sm btn-primary" (click)="DownloadArquivoUrl(item.Key)">
                        <i class="material-icons">download</i>Download
                      </button> 

                      <button *ngIf="usuario?.TipoUsuario == 1" type="button" class="btn btn-sm btn-secondary" (click)="obterUrl(item.Key)">
                            <i class="material-icons">link</i>Copiar Link
                      </button> 

                      <button *ngIf="usuario?.TipoUsuario == 1" type="button" class="btn btn-sm btn-outline-danger" (click)="confirm($event, item.Key)">
                            <i class="material-icons">cancel</i>Excluir
                      </button>
                      
                  </div>
                
                </div> 
              
            </div>
        </div>

    </ng-template>

    <p-dialog header="Retorno API" [(visible)]="modalError" [modal]="true" [style]="{width: '90vw'}" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">
        <div class="row">
            <p>{{modalErrorMessage | json}}</p>
        </div>

        <p-footer>
            <p-button icon="pi pi-check" label="Ok" class="p-button-text" (click)="modalError = false"></p-button>
        </p-footer>

    </p-dialog>

</form>

<p-confirmPopup></p-confirmPopup>

import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UsuarioViewModel } from '../models/usuario-view-model';
import { UsuariosService } from '../usuarios/usuarios.service';

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html'
})
export class PerfilUsuarioComponent implements OnInit {

  cadastroForm!: FormGroup;
  objUsuario: UsuarioViewModel = new UsuarioViewModel;
  
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private usuarioService: UsuariosService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {

    let usuarioString = JSON.parse(sessionStorage.getItem('usuario'));

    this.objUsuario.ativo = usuarioString['Ativo'];
    this.objUsuario.dataCadastro = usuarioString['DataCadastro'];
    this.objUsuario.email = usuarioString['Email'];
    this.objUsuario.id = usuarioString['Id'];
    this.objUsuario.nome = usuarioString['Nome'];
    this.objUsuario.senha = usuarioString['Senha'];
    this.objUsuario.tipoUsuario = usuarioString['TipoUsuario'];    
    
    this.criarFormulario();

  }

  criarFormulario() {

    this.cadastroForm = this.fb.group({

      id:           [ this.objUsuario.id, [Validators.required] ],
      nome:         [ this.objUsuario.nome, [Validators.required] ],
      email:        [ this.objUsuario.email, [Validators.required] ],
      senha:        [ this.objUsuario.senha, [Validators.required] ],
      tipoUsuario:  [ this.objUsuario.tipoUsuario, [Validators.required] ],
      dataCadastro: [ this.objUsuario.dataCadastro ],
      ativo:        [ this.objUsuario.ativo ],

    });

  }

  atualizar(){

    if(this.cadastroForm.invalid) return;    

    this.objUsuario = Object.assign({}, this.cadastroForm.value);

    // this.objUsuario.dataCadastro = formatDate(this.dataCadastro.value, 'yyyy-MM-dd', 'en-US')

    this.usuarioService.atualizar(this.objUsuario).subscribe(
      retorno => {
        this.messageService.add({
          severity: "success",
          summary: "Usuário",
          detail: "Atualizado com sucesso!"
        });
      },
      error => {
        this.messageService.add({
          severity: "error",
          summary: "Usuário",
          detail: "Erro ao atualizar usuário!"
        });
      }
    );

  }

  formatarData(date){
    return formatDate(date, 'dd/MM/yyyy', 'en-US')
  }

  get tipoUsuario(){ return this.cadastroForm.get('tipoUsuario') }
  get dataCadastro(){ return this.cadastroForm.get('dataCadastro') }

}

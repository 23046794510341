import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UsuarioViewModel } from '../models/usuario-view-model';
import { UsuariosService } from '../usuarios/usuarios.service';


@Component({
  selector: 'app-usuario-cadastro',
  templateUrl: './usuario-cadastro.component.html'
})
export class UsuarioCadastroComponent implements OnInit {

  cadastroForm!: FormGroup;
  alterar: boolean = false;

  modalError: boolean = false;
  modalErrorMessage: string = "";

  objUsuario: UsuarioViewModel = new UsuarioViewModel;


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private usuarioService: UsuariosService,
    private messageService: MessageService,

  ) { }

  ngOnInit() {

    this.criarFormulario();

    this.route.params.subscribe(
      params => {
         let id = parseInt(params['id']);

         if(id > 0) this.alterar = true;
         else return;

         this.usuarioService.obterPorId(id).subscribe(
          retorno => {
            this.objUsuario = retorno;
            this.criarFormulario();
          },
          error => {           
            
            this.modalErrorMessage = error;
    
            this.modalError = true;
          }
        );
      }   
   );
  }


  criarFormulario() {

    this.cadastroForm = this.fb.group({

      id:           [ this.alterar ? this.objUsuario.id : 0],
      nome:         [ this.alterar ? this.objUsuario.nome : null],
      email:        [ this.alterar ? this.objUsuario.email : null],
      senha:        [ this.alterar ? this.objUsuario.senha : null],
      tipoUsuario:  [ this.alterar ? this.objUsuario.tipoUsuario : 2],
      dataCadastro: [ this.alterar ? this.objUsuario.dataCadastro : new Date()],
      ativo:        [ this.alterar ? this.objUsuario.ativo : true],

    });

  }

  adicionar(){

    this.objUsuario = Object.assign({}, this.cadastroForm.value);
    
    // this.objUsuario.dataCadastro = formatDate(this.dataCadastro.value, 'yyyy-MM-dd', 'en');

    this.usuarioService.adicionar(this.objUsuario).subscribe(
      retorno => {
        console.log("Adicionado com sucesso!");

        this.messageService.add({
          severity: "success",
          summary: "Usuário",
          detail: "Cadastrado com sucesso!"
        });

        this.router.navigate([`usuario`]);
      },
      error => {
        
        this.messageService.add({
          severity: "error",
          summary: "Usuário",
          detail: "Erro ao gravar usuário"
        });

        this.modalErrorMessage = error;

        this.modalError = true;

      }
    );
  }

  atualizar(){

    this.objUsuario = Object.assign({}, this.cadastroForm.value);

    // this.objUsuario.dataCadastro = formatDate(this.dataCadastro.value, 'yyyy-MM-dd', 'en')

    this.usuarioService.atualizar(this.objUsuario).subscribe(
      retorno => {

        this.messageService.add({
          severity: "success",
          summary: "Usuário",
          detail: "Atualizado com sucesso!"
        });

      },
      error => {
                
        this.messageService.add({
          severity: "error",
          summary: "Usuário",
          detail: "Erro ao atualizar usuário"
        });

        this.modalErrorMessage = error;

        this.modalError = true;

      }
    );

  }

  onSubmit(){
    this.alterar ? this.atualizar() : this.adicionar();
  }

  formatarData(date){
    return formatDate(date, 'dd/MM/yyyy', 'en')
  }

  get dataCadastro(){ return this.cadastroForm.get('dataCadastro') }

}

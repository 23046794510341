import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ObjetoS3ViewModel } from '../models/objeto-s3-view-model';


@Injectable({
  providedIn: 'root'
})
export class AwsS3Service {

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  obterObjetoAWSS3(): Observable<any>{
    return this.http.get(`${this.baseUrl}api/ConsultaS3/ObterObjetoAws`);
  }

  ObterClientesMaxdata(): Observable<any>{
    return this.http.get(`${this.baseUrl}api/ConsultaS3/ObterClientesMaxdata`);
  }

  salvarObjetoCompleto(listaObjetoAws: Array<any>): Observable<any>{
    return this.http.post(`${this.baseUrl}api/ConsultaS3/SalvarObjetoCompleto`, {objeto: JSON.stringify(listaObjetoAws)});
  }

  obterTodos(): Observable<any>{
    return this.http.get(`${this.baseUrl}api/ConsultaS3`);
  }

  obterUltimo(): Observable<any>{
    return this.http.get(`${this.baseUrl}api/ConsultaS3/ObterUltimoObjeto`);
  }

  obterPorId(id: number): Observable<any>{
    return this.http.get(`${this.baseUrl}api/ConsultaS3/${id}`);
  }

  obterUrlDownload(keyFull: string): Observable<any> {
    return this.http.post(`${this.baseUrl}api/ConsultaS3/ObterUrl/`, { Key: `${keyFull}` });
  }
  
  removerObjetoS3(key: string): Observable<any> {
    return this.http.post(`${this.baseUrl}api/ConsultaS3/RemoverItem/`, { Key: `${key}` });
  }

  obterBackupManager(): Observable<any>{
    return this.http.get(`${this.baseUrl}api/ConsultaS3/ObterBackupManager`);
  }

  obterBackupWeb(): Observable<any>{
    return this.http.get(`${this.baseUrl}api/ConsultaS3/ObterBackupWeb`);
  }

  ObterTodosBackups(): Observable<any>{
    return this.http.get(`${this.baseUrl}api/ConsultaS3/ObterTodosBackups`);
  }

  novaConsultaS3(): Observable<any>{
    return this.http.get(`${this.baseUrl}api/ConsultaS3/NovaConsultaS3`);
  }

}

<form>
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        
            <nav aria-label="breadcrumb" class="mb-3">
            <h5>Usuário - Pesquisa</h5>
            </nav>
        </div>
        
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="text-right btns-group">
                <!-- <a class="btn btn-sm btn-outline-secondary" target="_blank" ><i class="material-icons">print</i> Imprimir</a> -->
                <a [routerLink]="['/usuario-novo']" class="btn btn-sm btn-success"><i class="material-icons">add</i>Novo</a>
            </div>
        </div>
    
    </div>
    
    <div class="card mt-3">
        <div class="card-body">
        
            <div class="row">
                
                <div class="col-sm-6 col-md-3 col-lg-2 col-xl-1">
                    <div class="form-group">
                        <label for="id" class="col-form-label-sm">Código</label>
                        <input type="number" id="id" name="id" class="form-control form-control-sm noscroll"
                            placeholder="Código" >
                    </div>
                </div>
    
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                    <div class="form-group">
                        <label for="email" class="col-form-label-sm">Email</label>
                        <input type="email" id="email" name="email"
                            class="form-control form-control-sm" placeholder="Email do Usuário"
                            autocomplete="off"/>
                    </div>
                </div>
    
                <div class="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                    <div class="form-group">
                        <label for="fantasia" class="col-form-label-sm">Nome</label>
                        <input type="text" id="fantasia" name="fantasia"
                            class="form-control form-control-sm" placeholder="Nome"
                            autocomplete="off"/>
                    </div>
                </div>
    
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-1">
                    <div class="form-check mt-4">
                      <input type="checkbox" class="form-check-input" id="ativo" checked="true">
                      <label class="form-check-label " for="ativo" >Ativo</label>
                    </div>
                </div>
    
                <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mt-3">
                    <div class="btns-group">
                        <button class="btn btn-sm btn-dark" ><i class="material-icons">search</i>Pesquisar</button>
                    </div>
                </div>
    
            </div>
    
    
          <div class="card-body pt-1 pb-1 bg-white card-body-table">
    
                <div class="table-responsive-sm">
                    <table class="table table-sm table-borderless table-striped table-hover ">
                        <thead class="thead">
                            <th class="text-nowrap" >Código</th>
                            <th class="text-nowrap" >Email</th>
                            <th class="text-nowrap" >Nome</th>
                            <th class="text-nowrap" >Data Cadastro</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of usuarios" class="cursor" routerLink="/usuario-editar/{{item.id}}">
                                <td>{{item.id}}</td>
                                <td>{{item.email}}</td>
                                <td>{{item.nome}}</td>
                                <td>{{item.dataCadastro | date: 'dd/MM/yyyy'}}</td>
                                <td class="text-center">
                                    <div class="badge  {{item.tipoUsuario == 1 ? 'bg-warning' : 'bg-primary'}} text-dark" placement="left">{{item.tipoUsuario == 1 ? 'ADMIN' : 'SUPORTE'}}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
    
        </div>
    
    
        </div>
    </div>

    <p-dialog header="Retorno API" [(visible)]="modalError" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">
        <div class="row">
            <p>{{modalErrorMessage | json}}</p>
        </div>

        <p-footer>
            <p-button icon="pi pi-check" label="Ok" class="p-button-text" (click)="modalError = false"></p-button>
        </p-footer>

    </p-dialog>

</form>

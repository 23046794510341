export class UsuarioViewModel {

    id?: number;
    nome?: string;
    email?: string;
    senha?: string;
    tipoUsuario?: 1 | 2;
    dataCadastro?: Date;
    ativo?: boolean;

}
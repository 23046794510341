<header>
    <nav *ngIf="navMenu.menuVisible" class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow mb-3 shadow"
        style="background-color: #cacaca;">

        <a class="navbar-brand" [routerLink]="['/painel']"><img src="../../assets/logo_max_backup.svg" alt="../../assets/logo_max_backup.svg" width="120" height="40"></a>
        
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse"
                aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded" (click)="toggle()" >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-collapse collapse d-sm-inline-flex justify-content-end" [ngClass]="{ show: isExpanded }">

          <ul class="navbar-nav flex-grow">

              <li class="nav-item" *ngIf="usuario?.TipoUsuario == 1">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/aws-s3']">AWS-S3</a>
              </li>

              <li class="nav-item" >
                <a class="nav-link " routerLinkActive="active" [routerLink]="['/painel']">Painel Manager</a>
              </li>

              <li class="nav-item" *ngIf="usuario?.TipoUsuario == 1">
                <a class="nav-link " routerLinkActive="active" [routerLink]="['/painel-web']">Painel Web</a>
              </li>

              <li class="nav-item">
                <a class="nav-link " routerLinkActive="active" [routerLink]="['/cliente-sem-diretorio']">Cliente sem Diretório</a>
              </li>

              <li class="nav-item" *ngIf="usuario?.TipoUsuario == 1">
                <a class="nav-link " routerLinkActive="active" [routerLink]="['/usuario']">Usuários</a>
              </li>

              <li class="nav-item mr-5" >
                <a class="nav-link " routerLinkActive="active" [routerLink]="['/perfil-usuario']">Perfil</a>
              </li>

              <li class="nav-item cursor">
                <a class="nav-link" (click)="logout()">Sair</a>
              </li>

          </ul>

        </div>
        
    </nav>

</header>
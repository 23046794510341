import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { AwsS3Service } from '../aws-s3/aws-s3.service';
import { PainelViewModel } from '../models/painel-view-model';
import { UsuariosService } from '../usuarios/usuarios.service';



@Component({
  selector: 'app-painel',
  templateUrl: './painel.component.html'
})

export class PainelComponent implements OnInit {

 pesquisa: PainelViewModel = new PainelViewModel;

 objeto: Array<any> = [];
 objetoProcessado: Array<any> = [];
 paginaAtual : Number = 1 ;

 modalError: boolean = false;
 modalErrorMessage: string = "";

 ordemCrescente: Boolean = false;

 objetoCompletoFull: Array<any> = [];
 listaBackupsCliente: Array<any> = [];

 modalCliente: boolean = false;
 dataConsulta: string;

 cardAtivo: 1 | 2 | 3 = null;

 usuario = JSON.parse(sessionStorage.getItem('usuario'));
 listaConsultores: Array<any> = [];
 listaClientes: Array<any> = [];

   constructor(
      private route: ActivatedRoute,
      private awsS3Service: AwsS3Service,
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
      private primengConfig: PrimeNGConfig,
      private usuariosService: UsuariosService
   ) { }


   ngOnInit() {
   
      this.primengConfig.ripple = true;
      
      this.backupManager();
      
      this.pesquisa.configurarParametros();
      
      this.obterConsultores();
   
   }

   obterConsultores(){
      this.usuariosService.obterConsultores().subscribe(
         retorno => {
            this.listaConsultores = retorno;
         },
         error => {
            this.messageService.add({
               severity: "error",
               summary: "Consultor",
               detail: `Erro ao obter consultores!`
            });
         }
      );
   }

   backupManager(){
      this.awsS3Service.obterBackupManager().subscribe(
         retorno => {
      
         let completo = retorno;
         let objeto = JSON.parse(completo.Objeto);
         let objetoFiltrado = objeto.filter(item => item.Codigo > 0)
      
         this.objetoCompletoFull = JSON.parse(completo.Objeto);
         this.listaClientes = JSON.parse(completo.ClientesSemBabckup);
      
         this.objeto = objetoFiltrado;
         this.dataConsulta = completo.DataConsulta;
         this.pesquisa.configurarParametros();
         this.processarDiretorios();
         this.verificarBackup();
      
         },
         error =>{
         this.modalErrorMessage = error;
      
         this.modalError = true;
         }
      );
   }
  
   ordenarPorData(){

      if(this.ordemCrescente) {
         this.pesquisa.listarDiretorios.sort((a, b) => (a.LastModified < b.LastModified ? -1 : 1));
         this.ordemCrescente = false;
         return;
      }
      else{
         this.pesquisa.listarDiretorios.sort((a, b) => (a.LastModified > b.LastModified ? -1 : 1));
         this.ordemCrescente = true;
         return;
      }

   }

   ordenarPorTamanho(){

      if(this.ordemCrescente) {
         this.pesquisa.listarDiretorios.sort((a, b) => (a.Size < b.Size ? -1 : 1));
         this.ordemCrescente = false;
         return;
      }
      else{
         this.pesquisa.listarDiretorios.sort((a, b) => (a.Size > b.Size ? -1 : 1));
         this.ordemCrescente = true;
         return;
      }

   }

   ordenarPorTamanhoAnterior(){

      if(this.ordemCrescente) {
         this.pesquisa.listarDiretorios.sort((a, b) => (a.PreviusSize < b.PreviusSize ? -1 : 1));
         this.ordemCrescente = false;
         return;
      }
      else{
         this.pesquisa.listarDiretorios.sort((a, b) => (a.PreviusSize > b.PreviusSize ? -1 : 1));
         this.ordemCrescente = true;
         return;
      }

   }



   processarDiretorios(){

      let lista: Array<any> = [];
      let listaDecrescente: Array<any> = [];
      let codigos: Array<any> = [];

      this.objeto.forEach(item => {
         
         if(!codigos.includes(item.Codigo)){

            codigos.push(item.Codigo);

            listaDecrescente = this.objeto.filter( itemFiltrado => itemFiltrado.Codigo == item.Codigo )

            listaDecrescente.sort((a, b) => (a.LastModified > b.LastModified ? -1 : 1));

            let backupAtual = listaDecrescente[0];
            let backupAnteiror = listaDecrescente[1];

            if(backupAnteiror){
               backupAtual['PreviusSize'] = backupAnteiror.Size;
            }else{
               backupAtual['PreviusSize'] = 0;
            }

            lista.push(backupAtual);

         }

      });
   
      this.pesquisa.listarDiretorios = lista;
      this.objetoProcessado = lista;
      
   }


   verificarBackup(){
   
      this.pesquisa.listarDiretorios.forEach(item => {
   
            let dataObjeto = new Date(item.LastModified);
   
            if(dataObjeto < this.pesquisa.dt10 || dataObjeto < this.pesquisa.dt5){
               this.pesquisa.dezDiasBackup.push(item)
            }
            else if(dataObjeto < this.pesquisa.dt5 || dataObjeto < this.pesquisa.dt2){
               this.pesquisa.cincoDiasBackup.push(item)
            }
            else{
               this.pesquisa.doisDiasBackup.push(item)
            }
      })
   
   }

   obterObjetoCliente(itens){
   
      this.modalCliente = true;
   
      this.pesquisa.clienteSelecionadoCodigo = itens.Codigo;
      this.pesquisa.clienteSelecionadoUltimaModificacao = itens.LastModified;
      this.pesquisa.clienteSelecionadoNome = itens.Cliente != null ? itens.Cliente.cliNome : "";
      this.pesquisa.clienteSelecionadoFantasia = itens.Cliente != null ? itens.Cliente.cliFantasia : "";
      this.pesquisa.clienteSelecionadoFone1 = itens.Cliente != null ? itens.Cliente.cliFone : "";
      this.pesquisa.clienteSelecionadoFone2 = itens.Cliente != null ? itens.Cliente.cliCelular : "";
      this.pesquisa.clienteSelecionadoEmail = itens.Cliente != null ? itens.Cliente.cliEmail : "";

      this.obterListaBackupsCliente(itens.Codigo);
   
   }

   obterListaBackupsCliente(codigo: number){
      this.listaBackupsCliente = this.objetoCompletoFull.filter( item => {
         return item.Codigo == codigo;
      });

   }

   limparDadosObjetoCliente(){
   
      this.pesquisa.clienteSelecionadoCodigo = "";
      this.pesquisa.clienteSelecionadoStringDownload = "";
      this.pesquisa.clienteSelecionadoUltimaModificacao = "";
      this.pesquisa.clienteSelecionadoNome = "";
      this.pesquisa.clienteSelecionadoFantasia = "";
      this.pesquisa.clienteSelecionadoFone1 = "";
      this.pesquisa.clienteSelecionadoFone2 = "";
      this.pesquisa.clienteSelecionadoEmail = "";
      
      this.modalCliente = false;
   
   }

   pesquisarDiretorio(){

      this.cardAtivo = null;

      let query = {};

      if (this.pesquisa.codigo) {
         query['cliId'] = this.pesquisa.codigo;
      }

      if (this.pesquisa.consultor) {
         query['clivendPref'] = this.pesquisa.consultor;
      }

      this.pesquisa.listarDiretorios = this.objetoProcessado.filter( (item) => {
 
         if(item.Cliente){ // Verifica se o cliente é null
            
            for (let key in query) {
               if (!item.Cliente[key] || !query[key].toString().includes(item.Cliente[key])) { // Filtro dinâmico  
                  return false;
               }
            }

            if(this.pesquisa.razao_fantasia){ //verificar por Razão / Fantasia
               return (item.Cliente.cliFantasia.toLowerCase().indexOf(this.pesquisa.razao_fantasia) != -1 || 
               item.Cliente.cliNome.toLowerCase().indexOf(this.pesquisa.razao_fantasia) != -1);
            }
   
            return true;

         }

      })

   }

  pesquisarDiretorios2DiasSemBackup(){
   this.pesquisa.listarDiretorios = this.pesquisa.doisDiasBackup;
   this.paginaAtual = 1;
  }

  pesquisarDiretorios5DiasSemBackup(){
   this.pesquisa.listarDiretorios = this.pesquisa.cincoDiasBackup;
   this.paginaAtual = 1;
  }

  pesquisarDiretorios10DiasSemBackup(){
   this.pesquisa.listarDiretorios = this.pesquisa.dezDiasBackup;
   this.paginaAtual = 1;
  }


  DownloadArquivoUrl(key: string){
     this.awsS3Service.obterUrlDownload(key).subscribe(
      retorno => {
         this.messageService.add({
            severity: "success",
            summary: "Download",
            detail: "Realizado realizando o Download do arquivo!"
          }); 

         window.location.href = retorno;
       },
       error => {

         this.messageService.add({
            severity: "erro",
            summary: "Falha no download o arquivo!",
            detail: `StatusCode: ${error.status} Status: ${error.statusText}`
         });

         this.modalErrorMessage = error;

         this.modalError = true;

       }
     );
  }

  obterUrl(key: string){

   if(!key){
      this.messageService.add({
         severity: "error",
         summary: "Erro",
         detail: "Falha ao copiar link"
       }); 
   }

   this.awsS3Service.obterUrlDownload(key).subscribe(
      retorno => {

         var elementoFicticio = document.createElement("textarea");
         document.body.appendChild(elementoFicticio);
         elementoFicticio.value = retorno;
         elementoFicticio.select();
         document.execCommand("copy");
         document.body.removeChild(elementoFicticio);

          this.messageService.add({
            severity: "success",
            summary: "Copiado",
            detail: "O Link expira em de 2h"
          });
       },
       error => {
         this.messageService.add({
            severity: "error",
            summary: "Erro ao gerar Link",
            detail: `StatusCode: ${error.status} Status: ${error.statusText}`
          });

          this.modalErrorMessage = error;

          this.modalError = true;
       }
     );

  }

  removerObjetoS3(key: string){

   if(!key){
      this.messageService.add({
         severity: "error",
         summary: "Erro ao Remover",
         detail: "Chave AWS-S3 inválida"
       });
       return;
   }

     this.awsS3Service.removerObjetoS3(key).subscribe(
        retorno => {

            if(retorno.httpStatusCode == 204){

               this.messageService.add({
                  severity: "success",
                  summary: "Sucesso",
                  detail: "Arquivo excuído com sucesso!"
                });                
            }
            else{
               this.messageService.add({
                  severity: "info",
                  summary: "Situação",
                  detail: `Status Code AWS-S3 ${retorno.httpStatusCode}`
                }); 
            }
        },
        error => {
         this.messageService.add({
            severity: "error",
            summary: "Erro ao Excluir Arquivo",
            detail: `StatusCode: ${error.status} Status: ${error.statusText}`
          });

          this.modalErrorMessage = error;

          this.modalError = true;
        }
     );

  }

  confirm(event: Event, key: string) {
      
      this.confirmationService.confirm({
         
        target: event.target,
        message: "Esse é um método irreversível, tem certeza de que deseja excluir?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Confirmar?",
        rejectLabel: "Abortar",
        rejectButtonStyleClass: "p-button-outlined p-button-danger",

        accept: () => {

         this.removerObjetoS3(key);

        },
        reject: () => {
          this.messageService.add({
            severity: "error",
            summary: "Abortado",
            detail: "Processo não realizado!"
          });
        }

      });
  }




}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioViewModel } from '../models/usuario-view-model';
import { NavMenuService } from '../nav-menu/nav-menu.service';
import { UsuariosService } from '../usuarios/usuarios.service';
import { version } from 'package.json';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public version: string = version;

  email: string;
  senha: string;
  objUsuario: UsuarioViewModel = new UsuarioViewModel;

  processandoLogin: boolean = false;
  loginMensagem: string = 'Autenticando...';
  loginStyle: string = 'btn-dark';

  modalError: boolean = false;
  modalErrorMessage: string = "";


  usuario = JSON.parse(sessionStorage.getItem('usuario'));

  constructor(
    private router: Router,
    public navMenu: NavMenuService,
    private usuarioService: UsuariosService,
    private messageService: MessageService
  ) {
    
    this.navMenu.hide();

    if(this.usuario) {
      
      this.router.navigate([`painel`]);

      this.navMenu.show();
    }

   }

  ngOnInit(){
   

  }

  login(){

    this.objUsuario.email = this.email;
    this.objUsuario.senha = this.senha;

    if(!this.email || !this.senha) return;

    this.processandoLogin = true;

    this.usuarioService.obterPorEmail(this.objUsuario).subscribe(
      retorno => {
        this.objUsuario = retorno;
        this.processandoLogin = false;
        sessionStorage.setItem('usuario', JSON.stringify(this.objUsuario));
        this.router.navigate([`painel`]);
        
        this.navMenu.show();

        window.location.reload();
      },
      error => {

        this.processandoLogin = false;

        if(error.status === 401){
          this.messageService.add({
            severity: "error",
            summary: "Login",
            detail: "Usuário ou senha inválido!"
          });

          return;
        }else{
          this.messageService.add({
            severity: "error",
            summary: "Retorno do Backend",
            detail: `StatusCode: ${error.status} Status: ${error.statusText}`
          });
          
        }

      }
    );

  }

}

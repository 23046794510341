import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavMenuService {
  menuVisible: boolean;

constructor() { this.menuVisible = true;}

hide() { this.menuVisible = false; }

show() { this.menuVisible = true; }

toggle() { this.menuVisible = !this.menuVisible; }

}

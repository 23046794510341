import { Component, OnInit } from '@angular/core';
import { UsuarioViewModel } from '../models/usuario-view-model';
import { UsuariosService } from './usuarios.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html'
})
export class UsuariosComponent implements OnInit {

  pesquisa: UsuarioViewModel = new UsuarioViewModel;

  usuarios: [] = [];

  modalError: boolean = false;
  modalErrorMessage: string = "";

  constructor(
    private usuarioService: UsuariosService
  ) { }

  ngOnInit() {
    this.pesquisar();
  }

  pesquisar() {

    this.usuarioService.obterTodos().subscribe(
      retorno => {
        this.usuarios = retorno.length > 0 ? retorno : [];
      },
      error => {

        this.modalErrorMessage = JSON.parse(error);

        this.modalError = true;
      }
    )
  }

}

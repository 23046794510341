<form>

    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <nav aria-label="breadcrumb" class="mb-3">
                <h5>AWS-S3 - Atualização</h5>
            </nav>
        </div>
    </div>
    
    <div class="card text-center" style="height: 20rem;">
    
        <div class="card-header">
            Total Geral de Bakups <b>Desktop:</b> {{Objeto.TotalItensDesktop}} | <b>Web:</b> {{Objeto.TotalItensWeb}}
        </div>
    
        <div class="card-body">
    
            <h5 class="card-title">Última Atualização: {{Objeto.DataConsulta | date: 'dd/MM/yyyy HH:mm' }}</h5>
            <p class="card-text">Atualização realizada de Forma: <span class="badge {{Objeto.TipoConsulta == 1 ? 'badge-success':'badge-warning'}}" >{{Objeto.TipoConsulta == 1 ? 'Automática':'Manual'}}</span></p>
    
            <button class="btn {{loginStyle}}  mt-4 mb-3" id="obter_objeto" type="button" (click)="novaConsultaS3()" [disabled]="processandoObjeto">
                <div [ngSwitch]="processandoObjeto">
                    <div *ngSwitchCase="true">
                        <span class="spinner-border spinner-border" role="status"
                            aria-hidden="true"></span>&nbsp;
                        <span>Processando...</span>
                    </div>
                    <div *ngSwitchDefault>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/>
                        </svg>
                            Atualizar <b>Backups</b>
                    </div>
                </div>
            </button>
    
        </div>
    
    </div>

    <p-dialog header="Retorno API" [(visible)]="modalError" [modal]="true" [style]="{width: '90vw'}" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">
        <div class="row">
            <p>{{modalErrorMessage | json}}</p>
        </div>

        <p-footer>
            <p-button icon="pi pi-check" label="Ok" class="p-button-text" (click)="modalError = false"></p-button>
        </p-footer>

    </p-dialog>

</form>


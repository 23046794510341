import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AwsS3Service } from './aws-s3.service';

@Component({
  selector: 'app-aws-s3',
  templateUrl: './aws-s3.component.html'
})
export class AwsS3Component implements OnInit {

  processandoObjeto: boolean = false;
  loginStyle: string = 'btn-dark';
  statusProcessamento: string = "";

  modalError: boolean = false;
  modalErrorMessage: string = "";

  listaObjeto: Array<any> = [];
  listaObjetoAws: Array<any> = [];
  listaObjetoCliente: Array<any> = [];

  Objeto = {
    TipoConsulta: 0,
    DataConsulta: '',
    TotalItensDesktop: 0,
    TotalItensWeb: 0,
  };

  constructor(
    private awsS3Service: AwsS3Service,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit() {

    this.ObterArquivo();
    
  }

  ObterArquivo(){
    this.awsS3Service.ObterTodosBackups().subscribe(
      retorno => {
        this.Objeto.TipoConsulta = retorno.TipoConsulta;
        this.Objeto.DataConsulta = retorno.DataConsulta;

        let objeto = JSON.parse(retorno.Objeto);

        this.calcularTotaisBackups(objeto);

      },
      error =>{

        this.messageService.add({
          severity: "error",
          summary: "Arquivo",
          detail: "Erro ao obter JSON"
        });

        this.modalErrorMessage = error;

        this.modalError = true;
        
      }
    );
  }

  novaConsultaS3(){

    this.processandoObjeto = true;
    this.loginStyle = 'btn-secondary';

    this.awsS3Service.novaConsultaS3().subscribe(
      retorno => {

        let objeto = JSON.parse(retorno);

        this.Objeto.TipoConsulta = objeto.TipoConsulta;
        this.Objeto.DataConsulta = objeto.DataConsulta;
        this.processandoObjeto = false;

        this.calcularTotaisBackups(JSON.parse(objeto.Objeto));

        this.loginStyle = 'btn-success';

        this.messageService.add({
          severity: "success",
          summary: "AWS-S3",
          detail: "Consulta realizada com sucesso!"
        }); 

      },
      error =>{

        this.processandoObjeto = false;

        this.messageService.add({
          severity: "error",
          summary: "Falha ao consultar AWS-S3",
          detail: `StatusCode: ${error.status} Status: ${error.statusText}`
        }); 

        this.modalErrorMessage = error;

        this.modalError = true;

      }
    );
  }
  
  calcularTotaisBackups(objeto){

    this.Objeto.TotalItensDesktop = 0;
    this.Objeto.TotalItensWeb = 0;

    objeto.map(itens => {
  
      if(itens.Key.split("/")[0] == "BackupS3"){
        this.Objeto.TotalItensDesktop++;
      }
      else{
        this.Objeto.TotalItensWeb++;
      }
    })
  }


}
